.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar-text:hover,
.navbar-active {
  border-bottom: 1px solid #8B3C7E;
}

.navbar-free-trial {
  background-color: #e6cede;
  font-size: 1.1vw;
  color: #8c3e7c;
  border-radius: 50px;
  font-weight: 600;
  padding: .2vw
}
@media (max-width: 1050px) { /* Mobile */
  .guideAriaanaIcon {
    visibility: hidden !important;
  }
}
@media (max-width: 800px) { /* Mobile */
  .navbar-free-trial,
  .navbar-text,
  .navbar-login,
  .navbar-more
   {
    font-size: 2.25vh !important;
  }

  .navbar-username {
    font-size: 2vh !important;
    width: 60vw !important;
    margin-top: -9vh !important;
    margin-left: 35vw !important;
    background-image: linear-gradient(to right, transparent -15%, rgba(230, 206, 222, 0.35) 50%, transparent 115%) !important;
  }

  .cookiesPopup,
  .acceptCookies {
    font-size: 4vw !important;
  }

  .oabutton {
    height: auto;
  }
  .mostrecOA {
    margin-left: -60% !important;
  }
}

.navbar-username {
  color: #8c3e7c;
  background-image: linear-gradient(to right, transparent -15%, rgba(230, 206, 222, 0.2) 50%, transparent 115%);
  font-size: 90%;
  font-weight: 500;
  margin-top: 60px;
  text-align: center;
  width: 14vw;
  position: absolute;
  cursor: pointer;
}

.navbar-more {
    height: 95% !important
}

.navbar-free-trial:hover {
  animation-name: shine;
  animation-duration: 260ms;
}

@keyframes shine {
  0% {
    background: linear-gradient(
      30deg, 
      hsl(260, 85%, 95%) 0%, 
      #e6cede 25%
    );
  }

  25% {
    background: linear-gradient(
      30deg, 
      #e6cede 0%, 
      hsl(260, 85%, 95%) 25%, 
      #e6cede 50%);
  }

  50% {
    background: linear-gradient(
      30deg,
      #e6cede 0%, 
      hsl(260, 85%, 95%) 50%, 
      #e6cede 75%); 
  }

  75% {
    background: linear-gradient(
      30deg, 
      #e6cede 0%, 
      hsl(260, 85%, 95%) 75%, 
      #e6cede 100%); 
  }

  100% {
    background: linear-gradient(
      30deg, 
      #e6cede 0%, 
      hsl(260, 85%, 95%) 100%); 
  }
}


/* Footer */
.footerwaves{
  background:linear-gradient(to bottom, transparent 8vh, #8c3e7c 10vh);
  text-align:center;
  position:relative;
  min-height:30vh;
  display:flex;
  justify-content:center;
  align-items:center;
  color: rgb(250, 245, 250);
}
.custom-shape-divider-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    height: 10vh;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(123% + 1.3px);
    height: 10vh;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom .shape-fill {
    fill: #8c3e7c;
}

.acceptCookies {
  background-color: transparent;
  cursor: pointer;
  border: 1px solid gray;
  padding: 3px 15px;
  font-size: 1vw;
}

.acceptCookies:hover {
  background-color: rgba(0,200,250,0.1) !important;
}

.cookiesPopup {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: hidden;
  white-space: nowrap;
  font-size: 1vw;
}


/* Tooltip */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 10vw;
  background-color: #8c3e7c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10%;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -5vw;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #8c3e7c transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 999 !important;
}

.nobordertooltip::after {
  border-color: transparent !important;
}

/* Dynamic Keepa Graph */
.round-checkbox {
  appearance: none;
  background-color: #E3D0DF;
  margin: 0.5em;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 0.05em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.round-checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #8B3C7E;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
} 

.round-checkbox:checked::before {
  transform: scale(1);
}

.form-control + .form-control {
  margin-top: 1em;
}

.keepagraphoos {
  cursor: url('/public/assets/settings-icon.png'), auto !important;
}

.keepagrapha2w {
  cursor: url('/public/assets/settings-icon.png'), auto !important;
}
.keepagrapha2w:hover {
  transform: scale(7) !important;
  z-index: 9 !important;
}

.oabutton {
  background-color: #E3D0DF;
  color: #8B3C7E;
  font-weight: 700;
  font-size: 110%;
  width: 100%;
  box-shadow: 0px 0px 4px 0px rgba(100, 100, 100, 0.5);
  border: 1px solid rgba(100, 100, 100, 0.5);
  padding: 5% !important;
}
.oabutton:hover,
.oabutton:active,
.oabutton:focus {
  color: #8B3C7E;
  box-shadow: 0px 0px 5px 0px rgba(100, 100, 100, 1);
}

.disableHoverCssDynamicKeepa:hover {
  transform: unset !important;
  scale: unset !important;
}

.guideCircle {
  transform: scale(0.95);
}
.guideCircle:hover {
  transform: scale(1);
}
.ariannattt {
  width: 4vw !important;
  line-height: 1vw !important;
  font-size: .75vw !important;
  margin-left: -2.1vw !important;
  margin-top: -3vh !important;
}

.dynamicKeepaTtOOS:hover {
  transform: scale(1.3) !important;
  z-index: 99999999999 !important;
}

.tutNotify {
  animation: beam 2s infinite;
}

@keyframes beam {
  0% {
    box-shadow: 0px 0px 5px 0px #8c3e7c;
  }
  50% {
    box-shadow: 0px 0px 15px 0px #8c3e7c;
  }
  100% {
    box-shadow: 0px 0px 5px 0px #8c3e7c;
  }
}

.tutNotifyIcon {
  animation: shake 2s infinite;
}

/* Adding a little number to the top right */
.tutNotifyIcon::after {
  content: "3"; /* The number you want to display */
  position: absolute;
  top: -8px; /* Adjust to position the badge */
  right: -8px; /* Adjust to position the badge */
  background-color: darkgreen;
  color: white;
  font-size: 18px;
  font-family: sans-serif;
  padding: 4px 8px;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  min-width: 20px;
  text-align: center;
  line-height: 1;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
}

.keepachartBtn:hover {
  width: 12vw !important;
  max-width: 12vw !important;
  height: 5.3vw !important;
  position: absolute !important;
  margin-left: -12vw !important;
}

.erbImage:hover {
  transform: scale(2.5);
  z-index: 9999999999 !important;
}